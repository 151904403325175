$(function() {
  $(".header__nav-item:first-of-type").on("mouseover ", function() {
    if ($(window).width() - getScrollbarWidth() > 768) {
      $("#doubledown-menu").addClass("active");
    }
  });
  $(".header__nav-item:first-of-type").on("click", function(e) {
    e.preventDefault();
    if ($("#doubledown-menu").hasClass("active")) {
      $("#doubledown-menu").removeClass("active");
    } else {
      $("#doubledown-menu").addClass("active");
    }
  });
  // $(".header__nav-item:first-of-type").on("mouseleave ", function(e) {
  //   if (!$("#doubledown-menu").hasClass("hovered")) {
  //     $("#doubledown-menu").removeClass("hovered");
  //   }
  // });
  $("#doubledown-menu")
    .on("mouseleave", function(e) {
      if ($("#doubledown-menu").hasClass("active")) {
        $("#doubledown-menu").removeClass("active");
        $("#doubledown-menu").removeClass("hovered");
      }
    })
    .on("mouseenter", function(e) {
      $("#doubledown-menu").addClass("hovered");
    });

  $(".doubledown-menu__nav a").on("click", function(e) {
    if ($(window).width() - getScrollbarWidth() > 1040) {
      e.preventDefault();
      let oldIndex = $(this)
        .parent()
        .find(".active")
        .index();
      $(".doubledown-menu__nav a").removeClass("active");
      $(this).addClass("active");
      let index = $(this).index();
      $(".doubledown-menu__item")
        .eq(oldIndex)
        .hide();
      $(".doubledown-menu__item")
        .eq(index)
        .fadeIn();
    }
  });
});

$(function() {
  var mySwiper;
  if ($(window).width() - getScrollbarWidth() < 560) {
    mySwiper = new Swiper(".companies__swiper", {
      slidesPerView: "auto",
      spaceBetween: 7
    });
  } else {
    if ($(".companies__swiper").hasClass("swiper-container-initialized")) mySwiper.destroy();
  }
  $(window).on("resize", function() {
    if ($(window).width() - getScrollbarWidth() < 560) {
      if (!$(".companies__swiper").hasClass("swiper-container-initialized"))
        mySwiper = new Swiper(".companies__swiper", {
          slidesPerView: "auto",
          spaceBetween: 7
        });
    } else {
      if ($(".companies__swiper").hasClass("swiper-container-initialized")) mySwiper.destroy();
    }
  });
});

$(document).ready(function() {
  let schoolSwiper = new Swiper(".school__slider", {
    direction: "horizontal",
    slidesPerView: 4,
    spaceBetween: 30,
    grabCursor: true,
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
      draggable: true
    },
    breakpoints: {
      992: {
        slidesPerView: 3
      },
      678: {
        slidesPerView: 2
      },
      560: {
        slidesPerView: "auto"
      }
    }
  });
});

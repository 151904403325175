$(function() {
  var mySwiper = new Swiper(".index-hero__container", {
    direction: "horizontal",
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 20,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    }
  });
});

$(function() {
  $(document).on("click", "[data-modal]", function(e) {
    e.preventDefault();
    var newModal = $(this).data("modal");
    if ($(".default-modal.is-active").length) {
      // prevModal = $(this).closest(".default-modal");
      // prevModal.removeClass("active");
      callbackClose();
      setTimeout(function() {
        // $('.'+newModal).addClass("active half");
        showModal(newModal);
      }, 300);
    } else {
      showModal(newModal);
    }
    // showModal($(this).data("modal"));
  });
  $(document).on("click", ".default-modal__close, [data-modal-close]", function() {
    callbackClose();
  });
  $("html").on("click", function(e) {
    if (!$(e.target).closest(".default-modal__content").length && $(".default-modal").hasClass("is-active")) {
      callbackClose();
    }
  });
  document.addEventListener("keydown", function(e) {
    if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
      callbackClose();
    }
  });
});
function showModal(e) {
  $("body").addClass("modal-open");
  var modal = $("." + e + "");
  // var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
  // $('html').addClass('no-scroll').css('top',-scrollTop);
  $("html").addClass("no-scroll");
  // console.log(modal);
  ///////////////////
  modalOpenMac();
  ///////////////////
  if (hasScrollbar()) {
    // с учетом горизонтального скролла. Чтобы небыло рывка при открытии модального окна
    var scrollWidth = getScrollbarWidth();
    // console.log(scrollWidth);
    $("html").css({
      "margin-right": scrollWidth
    });
    modal.addClass("is-active");
  } else {
    modal.addClass("is-active");
  }

  var isIE = /*@cc_on!@*/ false || !!document.documentMode;
  if (isIE === true) {
    // console.log(modal.find(".default-modal__content").outerHeight(), $(window).height());
    // var blockPosTop = modal.find(".default-modal__content").position().top;
    // if ( blockPosTop < 0 ) {
    //     console.log(blockPosTop)
    //     modal.css("display", "block");
    // }else {
    //     modal.removeAttr("style");
    // }
    if (modal.find(".default-modal__content").outerHeight() > $(window).height()) {
      // console.log(blockPosTop)
      modal.css("display", "block");
    } else {
      modal.removeAttr("style");
    }
  }
}
function callbackClose() {
  if ($("body").hasClass("modal-open") && !$("body").hasClass("mob-nav-open")) {
    $("body").removeClass("modal-open");
    $(".default-modal").removeClass("is-active");
    setTimeout(function() {
      // var scrollTop = parseInt($('html').css('top'));
      $("html").removeClass("no-scroll");
      // $('html,body').scrollTop(-scrollTop);
      $("html").css("margin-right", "");
      /////////////////////
      modalCloseMac();
      /////////////////////
      // $(".header__wrap").css('margin-right','');
    }, 300);
  } else if ($("body").hasClass("mob-nav-open")) {
    $("body").removeClass("modal-open");
    $(".default-modal").removeClass("is-active");
  }
}

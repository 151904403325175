$(function() {
  var aboutTop = new Swiper(".about-top__swiper", {
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 20,
    scrollbar: {
      el: ".about-top__scrollbar",
      draggable: true
    },
    navigation: {
      nextEl: ".about-top__next",
      prevEl: ".about-top__prev"
    }
    // freeMode: true
  });
  var aboutMain = new Swiper(".about-main__swiper", {
    direction: "horizontal",
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 20,
    navigation: {
      nextEl: ".about-main__next",
      prevEl: ".about-main__prev"
    }
    // freeMode: true
  });

  var aboutBottom = new Swiper(".about-bottom__swiper", {
    direction: "horizontal",
    // loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
      nextEl: ".about-bottom__next",
      prevEl: ".about-bottom__prev"
    },
    breakpoints: {
      1440: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 1
      }
    }
    // freeMode: true
  });
});
